import React from "react"
import { Container, Form, Button, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contato = () => (
  <Layout>
    <SEO title="Contato" keywords={[`estacionamento`, `juveve`, `lavacar`]} />
    <Container>
      <Row>
        <Col>
          <Form
            action="mailto:nathan-194@hotmail.com"
            method="post"
            enctype="text/plain"
          >
            <Form.Group controlId="formGroupName">
              <Form.Label>Nome</Form.Label>
              <Form.Control type="text" placeholder="Seu Nome" />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Seu Email" />
            </Form.Group>
            <Form.Group controlId="formGroupTextArea">
              <Form.Label>Mensagem</Form.Label>
              <textarea class="form-control" rows="5" id="comment" />
            </Form.Group>
            <Button type="submit">Enviar</Button>
          </Form>
        </Col>
        <Col>
          <div>Telefone: (41) 3252-1690 </div>
          <div>Celular: (41) 9 9270-6294 </div>
          <h3>Horário de Funcionamento </h3>
          <div>Segunda a Sábado: 8h às 19h </div>
        </Col>
      </Row>

      <h1 className="titulo--localizacao">Faça uma visita</h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7207.641584010368!2d-49.25871451931151!3d-25.41080865394485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce4212088488f%3A0x499932693ed19ade!2sR.+Em%C3%ADlio+Cornelsen%2C+71+-+Ahu%2C+Curitiba+-+PR%2C+80540-220!5e0!3m2!1spt-BR!2sbr!4v1555552350981!5m2!1spt-BR!2sbr"
        width="100%"
        height="600"
        frameborder="0"
        style={{ border: "0", margin: "auto" }}
        allowfullscreen=""
      />
    </Container>
  </Layout>
)

export default Contato
